/* 04.02 Brand CSS */

.brand-list{
    transition: var(--transition);
    &:hover{
        .brand{
            a{
                display: block;
                img{
                    opacity: 0.5;
                }
            }
        }
    }
    .brand{
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100px; /* Ajuste para definir uma altura uniforme para o contêiner */
        a{
            display: block;
            img{
                max-height: 100%;
                max-width: 100%;
                object-fit: contain; /* Garante que as imagens se ajustem mantendo a proporção */
                opacity: 1;
            }
        }
        &:hover{
            a{
                img{
                    opacity: 1;
                }
            }
        }
    }
}
